import '../styles/main.scss';
import { clearTimeout } from 'timers';

class Main {
  constructor() {
    this.content = document.querySelector('#content');
    this.email = document.querySelector('#email');
    this.navBtns = document.querySelectorAll('.change');
    this.workUrls = document.querySelectorAll('#workSamples a');
    this.devNotes = document.querySelector('#devAside');
    this.navItems = [];
  }
  init() {
    this.email.href = `mailto:${this.email.dataset.addy.replace(/2D/g, '')}`;
    Array.from(this.navBtns).forEach(el => {
      this.navItems.push(el.dataset.target);
      el.addEventListener('click', this.changeView.bind(this));
    });
    Array.from(this.workUrls).forEach(el => {
      el.addEventListener('mouseover', this.showAside.bind(this));
    });
    window.addEventListener(
      'touchstart',
      function onFirstTouch() {
        document.body.classList.add('touch-events');
        window.removeEventListener('touchstart', onFirstTouch, false);
      },
      false
    );
  }

  changeView(el) {
    const target = el.target.dataset.target;
    // const targetDom = this.content.querySelector('.on');
    this.content.classList.remove(...this.navItems);
    this.content.classList.add(target);
    // var page = document.documentElement;
    //   var transitionTime =
    //     parseFloat(
    //       getComputedStyle(page)
    //         .getPropertyValue('--close-time')
    //         .replace('s', '')
    //     ) * 1000;

    el.preventDefault();
  }
  hideAside() {
    this.devNotes.classList.add('off');
    while (this.devNotes.firstChild) {
      this.devNotes.removeChild(this.devNotes.firstChild);
    }
  }
  showAside(el) {
    this.hideAside();
    var aside = document.querySelector('#asideTemplate');
    aside.content.querySelector('.role').innerHTML = el.target.dataset.role ? el.target.dataset.role : '';
    aside.content.querySelector('.tools').innerHTML = el.target.dataset.tools ? el.target.dataset.tools : '';
    var clone = document.importNode(aside.content, true);
    this.devNotes.appendChild(clone);
    this.devNotes.classList.remove('off');
  }
}
export default Main;
